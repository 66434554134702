<template> 
	<v-card color="lightest" text v-if="content" class="mt-12 rounded-lg" flat>
		<v-card-text v-html="content.html"></v-card-text>
	</v-card>
</template>
<style></style>
<script>
export default {
	name: "ContentFooter",
	computed: {
		content() {
			return this.$store.state.content.data["genericfooter"];
		},
	},
	created() {
		this.$store.dispatch("content/fetchById", "genericfooter");
	},
};
</script>
